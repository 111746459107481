import marked from 'marked'

let renderMD = new marked.Renderer();

marked.setOptions({
    renderer: renderMD,
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false
});

export default {
    marked
}