<template>
    <div>
        <div class="back-container">
            <button class="back-btn" v-on:click="backToMain">返回</button>
        </div>
        <div class="detail-container">
            <div class="detail-title">
                <p>{{ detailTitle }}</p>
            </div>
            <div class="detail-date">
                <p>{{ detailTime }}</p>
            </div>
            <div class="detail-content markdown-body" v-html="compiledMarkdown"></div>
            <div class="detail-comment"></div>
        </div>
    </div>
</template>

<script>
    import '../assets/css/github-markdown.css'

    export default {
        name: "BlogDetail",
        props: ['blogId'],
        data() {
            return {
                detailTitle: "",
                detailTime: "",
                detailContent: ""
            }
        },
        computed: {
            compiledMarkdown: function () {
                return this.markedUtil.marked(this.detailContent, {sanitize: true})
            }
        },
        methods: {
            async getDetail() {
                try {
                    let response = await this.callApi.get('/blog/detail/' + this.blogId, null);
                    if (response.code === "0") {
                        this.detailTitle = response.data["blogTitle"];
                        this.detailTime = response.data["createTime"].substr(0, 19).replace('T', ' ');
                        this.detailContent = response.data["blogContent"];
                    } else {
                        alert(response.msg)
                    }
                } catch (e) {
                    alert(e);
                }
            },
            backToMain() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            },
        },
        created() {
            this.getDetail();
        }
    }
</script>

<style scoped>
</style>