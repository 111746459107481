<template>
    <div>
        <div class="srt-title">
            <label>Samin's Blog</label>
            <label style="font-size: 20px">早已发现该做些什么，现在开始去记录</label>
            <label>Starting in 2019-12-8</label>
        </div>

        <div class="srt-content">
            <div class="srt-log" v-for="item in blogContentList" v-bind:key="item.blogDate">
                <label class="srt-log-title" v-on:click="showDetail(item.id)">
                    {{item.blogTitle}}</label>
                <div class="srt-log-date">
                    <span class="srt-log-date-icon"></span>
                    <span class="srt-log-date-text">{{item.blogSubTitle}}</span>
                </div>
                <div class="srt-log-content">
                    <img class="srt-log-img" :src="item.blogImgUrl"/>
                    <label class="srt-log-text" v-html="item.blogDesc"></label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BlogContent",
        data() {
            return {
                blogContentList: []
            }
        },
        methods: {
            async getBlogs() {
                try {
                    let response = await this.callApi.get('/blog/list', null);
                    if (response.data.length > 0) {
                        for (let ele in response.data) {
                            response.data[ele].blogImgUrl = this.sysConfig.resUrl + "/" +
                                response.data[ele].blogImgUrl;
                        }
                    }
                    this.blogContentList = response.data;
                } catch (e) {
                    alert(e);
                }
            },
            showDetail(id) {
                this.$router.push("/detail/" + id)
            }
        },
        mounted() {
            this.getBlogs();
        }
    }
</script>

<style scoped>

</style>