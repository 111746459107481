import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import routes from './route';
import markedUtil from "./config/markedUtil";
import callApi from './common/net'
import './assets/css/index.css'
import './assets/css/blog.css'
import config from "./config/config"

Vue.use(VueRouter);

Vue.config.productionTip = false;
Vue.prototype.callApi = callApi;
Vue.prototype.sysConfig = config;
Vue.prototype.markedUtil = markedUtil;

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')