import BlogContent from "./pages/BlogContent";
import BlogDetail from "./pages/BlogDetail";

export default [
    {
        path: '/main', component: BlogContent,
    },
    {
        path: '/detail/:blogId', component: BlogDetail, props: true
    }
]