<template>
    <div>
        <router-view></router-view>

        <div class="footer">
            <p>Copyright © 2023-2024 Samin. All rights reserved.</p>
            <p><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19156657号-1</a></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'app',
        methods: {},
        mounted() {
            this.$router.push('/main');
        }
    }
</script>

<style>
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
    }

    .footer > p {
        margin: 12px 0 0;
    }
</style>
