import Axios from "axios";
import config from "../config/config"

export default {
    get(url, configs) {
        return new Promise((resolve, reject) => {
            Axios.get(config.apiUrl + url,
                configs
            ).then(function (res) {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        })
    }
}